@import url("https://fonts.googleapis.com/css2?family=Changa+One:ital@0;1&family=Fanwood+Text:ital@0;1&display=swap");
@import "~/src/index.css";

* {
  margin: 0;
  padding: 0;
}

:root {
  --heading-text: "Changa One", cursive;
  --body-text: "Fanwood Text", serif;
  --border: 2px solid var(--black);
}

.hero-section {
  background-color: var(--hero-background);
  height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

h2 {
  text-align: center;
  font-family: var(--heading-text);
}

.intro {
  color: var(--heading);
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
}

.description {
  color: var(--hero-accent-text);
  font-style: normal;
  font-weight: 100;
  font-size: 60px;
  max-width: 1000px;

  /* Text outline from https://stackoverflow.com/a/55554289 */
  text-shadow: rgb(0, 0, 0) 2px 0px 0px, rgb(0, 0, 0) 2.83487px 0.981584px 0px, rgb(0, 0, 0) 2.35766px 1.85511px 0px, rgb(0, 0, 0) 1.62091px 2.52441px 0px, rgb(0, 0, 0) 0.705713px 2.91581px 0px, rgb(0, 0, 0) -0.287171px 2.98622px 0px, rgb(0, 0, 0) -1.24844px 2.72789px 0px, rgb(0, 0, 0) -2.07227px 2.16926px 0px, rgb(0, 0, 0) -2.66798px 1.37182px 0px, rgb(0, 0, 0) -2.96998px 0.42336px 0px, rgb(0, 0, 0) -2.94502px -0.571704px 0px, rgb(0, 0, 0) -2.59586px -1.50383px 0px, rgb(0, 0, 0) -1.96093px -2.27041px 0px, rgb(0, 0, 0) -1.11013px -2.78704px 0px, rgb(0, 0, 0) -0.137119px -2.99686px 0px, rgb(0, 0, 0) 0.850987px -2.87677px 0px, rgb(0, 0, 0) 1.74541px -2.43999px 0px, rgb(0, 0, 0) 2.44769px -1.73459px 0px, rgb(0, 0, 0) 2.88051px -0.838247px 0px;
}

@media screen and (max-width: 768px) {
  .description {
    font-size: 50px;
    overflow-x: hidden;
    width: fit-content;
  }
}

@media screen and (max-width: 668px) {
  .description {
    font-size: 35px;
    padding: 4%;
  }
}

.orange-star-one {
  position: absolute;
  left: 2%;
  top: 80px;
  width: 2.9vw;
  animation: scale 4s infinite linear;
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.orange-star-two {
  position: absolute;
  left: 8.5%;
  top: 120px;
  width: 3vw;
  animation: rotation-cw 7s infinite linear;
}

@keyframes rotation-cw {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.white-star-one {
  position: absolute;
  left: 3%;
  top: 150px;
  width: 4.5vw;
  animation: rotation-acw 11s infinite linear;
}

@keyframes rotation-acw {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.moon {
  position: absolute;
  left: 6.5%;
  top: 40px;
  width: 4.7vw;
  animation: shake 5s infinite ease-in-out;
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.orange-star-three {
  position: absolute;
  right: 3%;
  top: 70px;
  width: 8vw;
  height: 8vw;
  animation: rotation-cw 7s infinite linear;
}

.ellipse-one {
  position: absolute;
  width: 0.7vw;
  right: 6%;
  top: 20px;
}

.ellipse-two {
  position: absolute;
  width: 0.9vw;
  right: 12%;
  top: 60px;
}

.ellipse-three {
  position: absolute;
  width: 0.9vw;
  right: 2%;
  top: 140px;
}

.flower-one {
  position: absolute;
  left: 25%;
  top: 483px;
  width: 8vw;
  height: 8vw;
  animation: rotation-cw 7s infinite linear;
}

.white-star-two {
  position: absolute;
  left: 22%;
  top: 470px;
  width: 3vw;
  animation: rotation-acw 11s infinite linear;
}

.orange-star-four {
  position: absolute;
  left: 23.5%;
  top: 540px;
  width: 1.5vw;
  animation: scale 3s infinite linear;
}

.purple-star {
  position: absolute;
  right: 6%;
  top: 440px;
  width: 8vw;
  height: 8vw;
  animation: rotation-acw 14s infinite linear;
}

.white-star-three {
  position: absolute;
  right: 16%;
  top: 520px;
  width: 2vw;
  animation: scale 3s infinite linear;
}
