@import url("https://fonts.googleapis.com/css2?family=Changa+One:ital@0;1&family=Fanwood+Text:ital@0;1&display=swap");
@import "~/src/index.css";

* {
  margin: 0;
  padding: 0;
}

:root {
  --heading-text: "Changa One", cursive;
  --body-text: "Fanwood Text", serif;
  --border: 2px solid var(--black);
}

.main-details {
  width: 85%;
  margin: 1% auto;
  border: var(--border);
  padding: 1% 2%;
  background-color: var(--cream);
  font-size: 20px;
}

.project-summary {
  background-color: var(--cream);
  font-family: var(--heading-text);
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
}

.descriptionHeading,
.newHeading,
.challengesHeading {
  font-family: var(--body-text);
  text-align: left;
  margin: 2% 0;
}

.description-mini-project,
.newDescription,
.challengesDescription {
  font-family: var(--body-text);
  text-align: justify;
}

.button-zone {
  display: flex;
  justify-content: space-evenly;
}

.project-image {
  width: 100%;
  max-width: 400px;
  margin: 20px 0;
  border-radius: 8px;
}

@media screen and (max-width: 768px) {
  .project-summary {
    font-size: 25px;
  }

  .descriptionHeading,
  .newHeading,
  .challengesHeading {
    font-size: large;
  }

  .main-details{
  padding: 5%;
  margin: 3% auto;
  }
}
