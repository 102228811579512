@import "~/src/index.css";

.common-button {
  background-color: var(--orange);
  color: var(--black);
  border: var(--border);
  border-radius: 40px;
  padding: 15px 35px;
  font-family: var(--heading-text);
  font-size: 24px;
  margin-top: 25px;
  transition: all 0.5s;
}

.common-button:hover {
  cursor: pointer;

  box-shadow: 5px 5px 0px 0px var(--black);
  -webkit-box-shadow: 5px 5px 0px 0px var(--black);
  -moz-box-shadow: 5px 5px 0px 0px var(--black);

  translate: -5px -5px;
}

@media screen and (max-width: 768px) {
  .common-button{
    padding: 8px 20px;
    font-size: 18px;
    border-radius: 20px;
  }
}
