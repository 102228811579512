@import url("https://fonts.googleapis.com/css2?family=Changa+One:ital@0;1&family=Fanwood+Text:ital@0;1&display=swap");
@import "~/src/index.css";

* {
  margin: 0;
  padding: 0;
}

:root {
  --heading-text: "Changa One", cursive;
  --body-text: "Fanwood Text", serif;
  --border: 2px solid var(--black);
}

.navbar {
  display: flex;
  justify-content: space-between;
  font-family: var(--heading-text);
  background-color: var(--cream);
  border-bottom: var(--border);
  border-top: var(--border);
}

.logo-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 50px;
}

.logo-img {
  width: 73.04px;
  margin-right: 20px;
  height: 70px;
}

.logo-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 26.2222px;
  color: var(--black);
}

.nav-section {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-right: 50px;
  gap: 70px;
}

.nav-section a {
  font-weight: 400;
  font-size: 26.2222px;
  text-decoration: none;
  color: var(--black);
}

a:hover {
  color: var(--orange);
}

.logo-heading:hover {
  font-style: italic;
  cursor: pointer;
}

.logo-section:hover {
  cursor: pointer;
}

.mobile-menu-icon {
  display: none;
  right: 10px;
}

.misc-nav {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .nav-section {
    flex-direction: column;
    height: auto;
    margin: auto;
    gap: 20px;
  }

  /* .logo-section{
    flex-direction: column;
  } */

  .navbar {
    flex-direction: column;
    align-items: center;
  }

  .logo-section {
    width: 100%;
    justify-content: space-around;
  }

  .logo-heading{
    font-size: 20px;
  }

  .misc-nav {
    display: flex;
    align-items: center;
  }
  .mobile-menu-icon {
    display: block;
    background-color: var(--orange);
    color: var(--black);
    border: var(--border);
    border-radius: 30px;
    padding: 7px 25px;
    font-family: var(--heading-text);
    font-size: 20px;
    /* margin-top: 25px; */
    cursor: pointer;
  }
}
