@import url("https://fonts.googleapis.com/css2?family=Changa+One:ital@0;1&family=Fanwood+Text:ital@0;1&display=swap");
@import "~/src/index.css";

* {
  margin: 0;
  padding: 0;
}

:root {
  --heading-text: "Changa One", cursive;
  --body-text: "Fanwood Text", serif;
  --border: 2px solid var(--black);
  background-color: var(--hero-background); 
}

.project-overview-section {
  background: linear-gradient(
    0deg,
    var(--banner) 93%,
    var(--hero-background) 100%
  );
}

.proud-project-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 62.7953px;
  font-family: var(--heading-text);
  margin-top: 50px;
  color: var(--heading);
}

@media screen and (max-width: 768px) {
  .proud-project-heading,
  .loved-project-heading a {
    font-size: 43px;
    margin-top: 0;
  }

  .project-overview-section {
    margin-top: 0;
  }
}

.loved-project-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 62.7953px;
  font-family: var(--heading-text);
  margin-top: 50px;
  max-width: 763px;
  text-align: center;
  margin: auto;
  padding: 70px 0;
}

.loved-project-heading a {
  color: var(--black);
  text-decoration: none;
}

.loved-project-heading a:hover {
  color: var(--black);
}

.loved-project-heading:hover {
  text-decoration: underline;
  cursor: pointer;
}

.staple-section {
  padding: 7%;
  display: flex;
  justify-content: space-between;
  gap: 5%;
}

.staple-image {
  max-width: 550px;
}

@media screen and (max-width: 768px) {
  .staple-image {
    max-width: 271px;
  }

  .staple-section,
  .portfolio-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #staple-overview-heading,
  #portfolio-overview-heading {
    font-size: 34px;
    text-align: center;
    margin: 5% auto;
  }

  .staple-overview-para {
    text-align: justify;
    font-size: 18px;
    margin: 5% auto;
  }

  #portfolio-button-source,
  #portfolio-button-code,
  #staple-button-source,
  #staple-button-code {
    padding: 10px 18px;
  }
}

.staple-overview-heading {
  font-family: var(--heading-text);
  color: var(--black);
  font-style: normal;
  font-weight: 400;
  font-size: 55px;
}

.staple-overview-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1%;
}

.staple-overview-para {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  font-family: var(--body-text);
  color: var(--black);
}

.overview-button-section {
  display: flex;
  justify-content: space-between;
}

.overview-button {
  color: var(--black);
  border: var(--border);
  border-radius: 40px;
  padding: 15px 25px;
  font-family: var(--heading-text);
  font-size: 20px;
  transition: all 0.5s;
}

.overview-button.primary {
  background-color: var(--orange);
}

.overview-button.secondary {
  background-color: transparent;
}

.overview-button:hover {
  cursor: pointer;

  box-shadow: 5px 5px 0px 0px var(--black);
  -webkit-box-shadow: 5px 5px 0px 0px var(--black);
  -moz-box-shadow: 5px 5px 0px 0px var(--black);

  translate: -5px -5px;
}

.portfolio-section {
  padding: 7%;
  display: flex;
  justify-content: space-between;
  gap: 5%;
  min-height: 500px;
}


