@import url("https://fonts.googleapis.com/css2?family=Changa+One:ital@0;1&family=Fanwood+Text:ital@0;1&display=swap");
@import "~/src/index.css";

* {
  margin: 0;
  padding: 0;
}

:root {
  --heading-text: "Changa One", cursive;
  --body-text: "Fanwood Text", serif;
  --border: 2px solid var(--black);
}

.hire-me-scroll {
  width: 100%;
  overflow-x: hidden;
  background-color: var(--dark-purple);
  height: 60px;
  border: var(--border);
  display: flex;
  align-items: center;
}

.hire-me {
  position: relative;
  top: 1%;
  left: -2100px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-family: var(--heading-text);
  color: var(--black);
  font-style: normal;
  font-weight: 400;
  font-size: 26.2222px;
  text-transform: uppercase;
}

.hire-me-star {
  height: 30px;
  margin-right: 0;
  padding: 0 20px;
  
}

.contact-section {
  display: flex;
  align-items: center;
  padding: 75px 5%;
  background-color: var(--cream);
}

.contact-line {
  font-style: normal;
  font-weight: 400;
  font-size: 34.5029px;
  font-family: var(--heading-text);
  max-width: 572px;
}

@media screen and (max-width: 768px) {
  .contact-line {
    font-size: 24px;
  }

  #last-line{
    font-size: 16px;
    padding: 1.5%;
  }
}

.icon-link {
  height: 22px;
  width: 40px;
}

.contact-one {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 20px;
}

.last-line {
  /* height: 38px; */
  background-color: var(--orange);
  border: var(--border);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.7% 0;
}

.last-line p {
  font-family: var(--body-text);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
}
