@import url("https://fonts.googleapis.com/css2?family=Changa+One:ital@0;1&family=Fanwood+Text:ital@0;1&display=swap");

/* font-family: 'Changa One', cursive;
font-family: 'Fanwood Text', serif; */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --cream: #f6f3eb;
  --pink: #f6d7eb;
  --dark-purple: #5c0c42;
  --orange: #e19140;
  --black: #333333;

  /* Flower colors */
  --flower1 : #ceb13b;
  --flower2: #f391ab;

  --text: var(--black);
  --hero-background: var(--pink);
  --heading: var(--dark-purple);
  --hero-accent-text: var(--orange);
  --banner: var(--cream);
}

body{
  background-color: var(--hero-background) ;
}



